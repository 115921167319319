<!--
   User: Liu Yin
   Date: 2020/3/12
   Description:人才测评
 -->
<template>
  <article class="online-root">
    <swiperbanner v-if="pageType === 1" class="banner" :dict-code="Banner.BANNER_ASSESSMENT"></swiperbanner>
    <el-row
      :gutter="10"
      style="display: flex; align-items: center; justify-content: center"
    >
      <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
        <div v-if="pageType === 1">
          <el-card shadow="always" class="label_box">
            <div>
              <div class="directe">
                <ul v-for="(item, index) in firstLevel" :key="index">
                  <li
                    :class="item.atId === atId ? 'li_active' : ''"
                    @click="queryType(item)"
                  >
                    {{ item.cuName }}
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
          <div style="position: relative;margin: 0 auto 40px;max-width: 1280px;">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1">综合排序</el-menu-item>
              <el-menu-item index="2">免费</el-menu-item>
              <el-menu-item index="3">收费</el-menu-item>
            </el-menu>
            <el-input
              v-model="searchForm.keyWords"
              placeholder="搜索您感兴趣的测评"
              class="inputwidth" @change="requestData">
              <i slot="suffix" class="el-icon-search"></i>
            </el-input>
          </div>
          <!-- <div class="row" style="margin-bottom:18px;margin-left: 205px;">
                <img src="@/assets/images/free.png" class="custom-screening" @click="handleSeach('free')">
                <img src="@/assets/images/charge.png" class="custom-screening" @click="handleSeach('charge')">
              </div> -->
          <div class="container-right">
            <appraisal-list
              v-if="tableData.length>0"
              :records="tableData"
              is-mine
              :total="total"
              :current-page="current"
              @startAppraisal="startAppraisal"
              @showIntroduce="handleShowIntroduce"
              @changePage="handleCurrentChange"
            ></appraisal-list>
            <el-empty v-else description="人才测评暂无测评数据"></el-empty>
          </div>
        </div>
      </el-col>
    </el-row>
    <appraise-introduce
      v-if="pageType === 2"
      :row-data="rowData"
      :appraise-record="appraiseRecord"
      @close="pageType = 1"
    ></appraise-introduce>
    <appraisal-report
      v-if="pageType === 3"
      :row-data="rowData"
      @close="pageType = 1"
      @startAppraisal="startAppraisal"
    ></appraisal-report>
  </article>
</template>

<script>
// import listMixin from '../../../../utils/mixins/listMixin'
import appraisalList from './components/appraisalList'
import appraiseIntroduce from './components/appraiseIntroduce'
import appraisalReport from './appraisalReport'
import imageTop from '../../../../assets/images/appraise-top.png'
import Swiperbanner from '../../../../components/swiperbanner.vue'
import { Banner } from '../../../../utils/dict'
export default {
  components: {
    Swiperbanner,
    appraisalList,
    appraiseIntroduce,
    appraisalReport
  },
  // mixins: [listMixin],
  data() {
    return {
      Banner,
      // 1列表  2答题  3结果
      pageType: 1,
      // menuList: [
      //   { title: '职业素养', active: true, type: 'ZYSY' },
      //   { title: '性格倾向', active: false, type: 'XGXQ' },
      //   { title: '知识技能', active: false, type: 'ZSJN' },
      //   { title: '心理健康', active: false, type: 'XLZT' },
      //   { title: '组合类', active: false, type: 'ZHL' }
      // ],
      firstLevel: [],
      secondLevel: [],
      searchForm: {},
      rowData: {},
      appraiseRecord: {},
      tableData: [],
      current: 1,
      size: 12,
      total: 0,
      // 测评查询参数
      queryData: {
        atType: 2
      },
      atId: 0,
      activeIndex: '1'
    }
  },
  created() {
    document.body.scrollTop = 0
    this.requestData()
    this.requestType()
  },
  methods: {
    getImage(item) {
      if (item.asUrl) return item.asUrl
      return imageTop
    },
    // 图片链接失效/错误给默认图
    handleError(event) {
      const img = event.srcElement
      img.src = imageTop
      img.onerror = null // 防止闪图
    },
    requestData() {
      this.searchForm.atType = 2
      this.$get(this.urls.assessList, this.searchForm, [
        this.current,
        this.size
      ])
        .then((result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            this.tableData = data.records
            this.total = data.total
          } else {
            this.$message.error(result.msg)
          }
        })
        .catch((err) => {
          console.log('err==', err)
        })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      if (key === '1') {
        this.searchForm.cost = ''
      } else if (key === '2') {
        this.searchForm.cost = 'free'
      } else if (key === '3') {
        this.searchForm.cost = 'charge'
      }
      this.current = 1
      this.$forceUpdate()
      this.requestData()
    },
    // 查询测评类型1/2级方法
    queryType(row) {
      console.log(row)
      this.atId = row.atId
      this.searchForm.asType = row.atCode
      this.requestType()
      this.requestData()
    },
    // 获取课程分类&一二级
    requestType() {
      this.$get('/portal/getDomainType', this.queryData).then((result) => {
        this.data = result.data

        if (this.queryData.atPid === 0 || !this.queryData.atPid) {
          this.firstLevel = []
          this.firstLevel.unshift({ cuName: '全部', atPid: 0, atId: 0 })
        }
        this.data.forEach((item) => {
          if (item.atPid === 0) {
            this.firstLevel.push(item)
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.current = val
      this.requestData()
    },
    handleShowIntroduce(item) {
      this.$get(this.urls.assessClickCount, null, [item.asId]).then(result => {})
      this.rowData = item
      this.appraiseRecord = null
      this.pageType = 2
    },
    startAppraisal(item) {
      this.rowData = item
      this.showIntroduce = true
    }
  }
}
</script>

<style lang="scss" scoped>
.online-root {
  overflow-x: hidden;
  // width: 67%;

  margin: auto;
}
.label_box {
  max-width: 1280px;
  border-radius: 8px;
  // margin: 24px 0 40px 0;
      margin: 24px auto 40px;
  width: 100%;
}
ul {
  display: inline-block;
  li {
    box-sizing: border-box;
    margin: 0 8px 8px 0;
    padding: 6px 8px;
    color: #4e5969;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.li_active {
  color: #2c6efb;
  background-color: #e8f3ff;
}
.field_direction {
  display: inline-block;
}
.directe {
  margin-bottom: 10px;
}
.flexcencen {
  // display: flex;
  //   align-items: center;
  //   justify-content: center;
}
.el-menu.el-menu--horizontal {
  width: 100%;
}
.inputwidth {
  position: relative;
  width: 43%;
  position: absolute;
 top: 25px;
    right: 20px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  font-weight: bold;
}
.el-menu--horizontal > .el-menu-item {
  font-size: 18px;
  line-height: 48px;
  margin-right: 10px;
}
::v-deep .el-input__suffix {
  position: absolute;
  top: 12px !important;
}
.container-right{margin: 0 auto;}
.banner {
  width: 100%;
  height: 430px;
}
.el-menu-demo{
  padding: 12px 20px;
    border-radius: 8px;
}
</style>
