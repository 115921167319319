<!--
   User: Liu Yin
   Date: 2020/4/24
   Description:测评介绍
 -->
<template>
  <article>
    <!-- <return-key @close="handleClose"></return-key> -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><span @click="handleClose">人才测评</span></el-breadcrumb-item>
        <el-breadcrumb-item>测评</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="width:1212px;margin: 0 auto;">

      <article v-if="pageType===1" style="padding: 20px 20px 50px 20px;line-height: 1.5715;">
        <!-- <div class="resumepad-style">
        <div class="backdiv" @click="handleClose">
          <i class="iconfont icon-return"></i>
          返回
        </div>
      </div> -->

        <article class="appraise-detail">
          <div class="detailButton">
            <img :src="getImage(appraisalData)" alt="" class="item-container" @error="handleError">
            <section style="margin-left: 20px;position: relative;">
              <div class="title">{{ appraisalData.asName }}</div>
              <div class="topicNum">
                <span>共{{ appraisalData.asNumber }}道题</span>
                <span>建议时长{{ appraisalData.asDuration }}分钟</span>
              </div>
              <el-button type="primary" size="small" style="position: absolute;left: 0;bottom: 0;" @click="handleStartAppraisal(rowData)">开始测评</el-button>
              <el-button v-if="rowData.apStatus===3&&rowData.reviewStatus===$dict.ReviewStatus.FINISHED" style="position: absolute;left: 80px;bottom: 0;" type="primary" size="small" @click.stop.prevent="showReport(rowData)">查看报告</el-button>
              <el-dropdown v-if="rowData.apStatus===3&&rowData.reviewStatus===$dict.ReviewStatus.FINISHED" trigger="click" style="position: absolute;left: 180px;bottom: 0;">
                <el-button type="primary" size="small" @click.stop.prevent="showHistoryReport(rowData)">
                  历史报告
                </el-button>
                <!-- <span class="el-dropdown-link" @click.stop.prevent="showHistoryReport(rowData)">
                历史测评报告<i class="el-icon-arrow-down el-icon--right"></i>
              </span> -->
                <el-dropdown-menu v-if="historyList.length>0" slot="dropdown">
                  <el-dropdown-item v-for="(val,num) in historyList" :key="num" @click.native="seeHisReport(rowData,val)">{{ val.createTime | moment("YYYY-MM-DD") }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </section>
          </div>
          <div class="intro-info">
            <div class="intro-title">测评介绍:</div>
            <div class="html-content" v-html="appraisalData.asDetails"></div>
          </div>
          <div class="intro-info">
            <div class="intro-title">测评须知:</div>
            <div class="html-content" v-html="appraisalData.asSurvey"></div>
          </div>

        </article>
      </article>
      <do-appraisal v-if="pageType===2" :appraisal-info="appraisalData" :history-appraisal="historyAppraisal" :plan-id="planId" @toIndex="handleClose" @commitSuccess="handleCommitSuccess" @close="handleClose"></do-appraisal>
    </div>
  </article>
</template>

<script>
import backgroundImage from '../../../../../assets/images/appraise-top.png'
import doAppraisal from './doAppraisal'
import { getToken } from '../../../../../utils/auth'
import Cookies from 'js-cookie'
export default {
  components: {
    doAppraisal
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {}
      }
    },
    // 培训计划ID
    planId: {
      type: [Object, Number],
      default: null
    },
    appraiseRecord: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      token: getToken(),
      testId: this.rowData.asId,
      appraisalData: {},
      historyAppraisal: {},
      childrenList: [],
      // 1介绍  2答题  3结果
      pageType: 1,
      params: {},
      detailData: {},
      historyList: [{
        createTime: '2023-06-27'
      }]
    }
  },
  created() {
    console.log(this.rowData, 'this.rowData')
    document.body.scrollTop = 0
    this.initData(this.rowData)
    if (this.appraiseRecord) {
      this.historyAppraisal = this.appraiseRecord
      // this.pageType = 2
    }
  },
  methods: {
    // 加默认封面
    getImage(item) {
      if (item.asUrl) return item.asUrl
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    handleError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    initData(data) {
      this.appraisalData = data
    },
    getDetali(item) {
      this.$get(this.urls.personAssessDetail, {}, [item.apId]).then(result => {
        if (result.code === 'SUCCESS') {
          this.detailData = result.data
          this.$emit('showReport', item, this.detailData)
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    showReport(item) {
      this.getDetali(item)
    },
    showHistoryReport(item) {
      let params = {}
      item.plid !== -1 ? params.plid = item.plid : params = null
      this.$get(this.urls.fetchLastFiveRecords + '/' + item.asId, params).then(result => {
        if (result.code === 'SUCCESS') {
          this.historyList = result.data
        }
      }).catch((e) => {
      })
    },
    seeHisReport(item, val) {
      this.$emit('showReport', item, val)
    },
    // getBackgroundStyle(item) {
    //   if (!item.asUrl) {
    //     return { backgroundImage: 'url(' + backgroundImage + ')' }
    //   }
    //   return { backgroundImage: 'url(' + item.asUrl + ')' }
    // },
    // 开始测评
    // startAppraisal (item) {
    //   if (item.asPlatform === 1) {
    //     // 跳转到睡前平台
    //     this.toggleLoading(true)
    //     this.$get(this.urls.talentDetail, { talentId: item.platformId }).then(result => {
    //       this.toggleLoading(false)
    //       if (result.code === 'SUCCESS') {
    //         window.open(result.data)
    //       }
    //     }).catch((e) => {
    //       this.toggleLoading(false)
    //     })
    //   } else {
    //     this.handleStartAppraisal()
    //   }
    // },
    handleStartAppraisal(val) {
      this.$get(this.urls.getLoginUser).then(result => {
        if (result.code === 'SUCCESS') {
          if (result.data.peopleType === '3') {
            // Cookies.set('enterprisetoken', null)
            // Cookies.set('managementtoken', null)
            // window.localStorage.removeItem('managementuser')
            // window.localStorage.removeItem('mutual-user')
            // window.localStorage.removeItem('navigation')
            window.localStorage.setItem('mutual-user', localStorage.getItem('user'))
            window.localStorage.setItem('navigation', '{"id":27,"parentId":0,"icon":"icon-gerenzhongxin","name":"个人中心","pathName":"enterpcourses","route":"/personcenter/enterpcourses","path":"/corp/personcenter/enterpcourses","children":[{"id":29,"parentId":27,"icon":"","name":"企业课程","pathName":"enterpcourses","route":"/personcenter/enterpcourses","path":"/corp/personcenter/enterpcourses"},{"id":30,"parentId":27,"icon":"","name":"企业测评","pathName":"enterevaluate","route":"/personcenter/enterevaluate","path":"/corp/personcenter/enterevaluate"},{"id":31,"parentId":27,"icon":"","name":"我的课程","pathName":"percourse","route":"/personcenter/percourse","path":"/corp/personcenter/percourse"},{"id":32,"parentId":27,"icon":"","name":"我的测评","pathName":"appraisal","route":"/personcenter/appraisal","path":"/corp/personcenter/appraisal","children":[]},{"id":33,"parentId":27,"icon":"","name":"我的培训","pathName":"persontask","route":"/personcenter/persontask","path":"/corp/personcenter/persontask","children":[]}]}')
            window.localStorage.setItem('enterEvaluateData', JSON.stringify(this.rowData))

            Cookies.set('enterprisetoken', this.token)
            // const routeData = this.$router.resolve({
            //   path: process.env.VUE_APP_ENTERPRISE_URL + '/personcenter/enterevaluate',
            //   query: {
            //     pageType: 2,
            //     rowData: JSON.stringify(this.rowData)
            //   }
            // })
            // window.open(routeData.href, '_blank')
            window.open(process.env.VUE_APP_ENTERPRISE_URL + '/personcenter/enterevaluate?pageType=2', '_blank')
          } else {
            this.$message.error('此账号无测评权限,请联系管理员开员工个人账号')
          }
        }
      }).catch(err => {
        console.log(err)
      })
      // const id = parseInt(this.testId)
      // this.params.asId = id
      // if (this.planId) {
      //   this.params.planId = this.planId
      // }
      // if (val.plid !== -1) {
      //   this.params.plid = val.plid
      // }
      // this.$post(this.urls.startAppraisal, this.params).then(result => {
      //   if (result.code === 'SUCCESS') {
      //     this.historyAppraisal = result.data
      //     this.pageType = 2
      //   } else {
      //     if (result.code === 'RELOAD') {
      //       this.$confirm(result.msg || result.message, '提示', {
      //         confirmButtonText: '确定',
      //         cancelButtonText: '取消',
      //         type: 'warning'
      //       }).then(() => {
      //         this.params.reEvaluation = true
      //         this.handleStartAppraisal()
      //       }).catch(() => {
      //       })
      //     } else {
      //       this.$message.error(result.msg || result.message)
      //     }
      //   }
      // }).catch(() => {
      // })
    },
    handleClose() {
      console.log(1)
      this.$emit('close')
    },
    handleCommitSuccess() {
      this.$emit('commitSuccess')
    }
  }
}
</script>

<style lang="scss" scoped>
.appraise-detail {
  width: 900px;
  margin: 0 auto;
  position: relative;
  min-height: 700px;
}
.title {
  font-size: 22px;
  color: #262626;
}
.label-icon {
  font-size: 20px;
  color: rgb(0, 140, 226);
}
.appraise-list {
  display: flex;
  flex-wrap: wrap;
}
.item-container {
  border-radius: 8px;
  overflow: hidden;
  width: 320px;
  height: 180px;
}
// .curPicture {
//   width: 100%;
//   // border-radius: 6px 6px 0 0;
//   border-radius: 20px 20px 0 0;
//   background: url('../../../../assets/images/appraise-top.png') no-repeat center;
//   background-size: cover;
// }
.backdiv {
  position: fixed;
  cursor: pointer;
  background: #b8f4f7;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.resumepad-style {
  position: absolute;
  top: 88px;
  right: 125px;
  z-index: 999;
}
.detailButton {
  display: flex;
}
.topicNum {
  margin-top: 20px;
  font-size: 14px;
  color: #595959;
}
.intro-title {
  font-size: 20px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.intro-title::before {
  content: "";
  background-color: #165dff;
  border-radius: 4px;
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  width: 4px;
}
.intro-info {
  margin-top: 56px;
}
.html-content {
  font-size: 14px;
  line-height: 38px;
}
.back {
  position: fixed;
  left: 20px;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.breadcrumb {
  width: 1280px;
  padding: 20px 10px;
  margin: 0 auto;
  cursor: pointer;
}
</style>
