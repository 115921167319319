<!--
   User: Liu Yin
   Date: 2021/3/17
   Description:
 -->
<template>
  <ul>
    <slot name="header"></slot>
    <li v-for="(item) in questionList" :key="item.aqId" style="border-bottom: solid rgba(232,238,243,0.76) 1px;padding-bottom: 20px;margin-top: 20px">
      <h3 style="margin-top: 10px;margin-bottom: 20px;line-height: 30px;font-weight: 400">
        <span>({{ item.aqTopic | convert('QuestionType') }})</span>
        <span style="margin-left: 10px">{{ item.aqName }}</span>
      </h3>
      <ul v-if="item.aqTopic===QuestionType.SINGLE_CHOICE || item.aqTopic===QuestionType.MULTIPLE_CHOICE || item.aqTopic===QuestionType.JUDGE">
        <li v-for="option in item.options" :key="option.qoId" class="choice-option" :class="{'right-answer':option.isRightAnswer,'error-answer':option.isErrorAnswer}">
          <span v-if="item.aqTopic!==QuestionType.JUDGE" class="option-key">{{ option.qoOption }}</span>
          <span style="margin-left: 10px">{{ option.qoContent }}</span>
        </li>
      </ul>

      <ul class="answer">
        <li v-if="showRecord&&reviewStatus===$dict.ReviewStatus.FINISHED" style="font-size: 14px;margin-bottom: 15px">
          <span>得分: {{ item.ansScore }}</span>
        </li>
        <li style="font-size: 14px;">总分:{{ item.ansScore }} / {{ item.aqScore }}</li>
        <li v-if="item.aqTopic!==QuestionType.BRIEF_ANSWER" style="font-size: 14px;color: #8ba0ac;margin-top: 15px">正确答案: {{ replaceSpecialSymbol(item.qoId) }}</li>
        <li v-if="showRecord" style="font-size: 14px;margin-top: 15px">我的答案: {{ replaceSpecialSymbol(item.ansValues) }}</li>
        <li style="font-size: 14px;margin-top: 15px">答案解析: {{ item.aqAnalysis }}</li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { QuestionType } from '../../../../../utils/dict'

export default {
  props: {
    questionList: {
      type: Array,
      default() {
        return []
      }
    },
    reviewStatus: {
      type: [Number, String],
      default: 0
    },
    // 是否展示答题信息
    showRecord: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      QuestionType
    }
  },
  methods: {
    replaceSpecialSymbol(str) {
      if (!str) return
      return str.replace(/##/g, '、')
    }
  }
}
</script>

<style lang="scss" scoped>
.right-answer {
  color: #13ce66;
  .option-key {
    border: 1px solid #13ce66;
  }
}
.error-answer {
  color: #ff4949;
  .option-key {
    border: 1px solid #ff4949;
  }
}
.choice-option {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  .option-key {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 13px;
  }
}
.answer {
  min-height: 60px;
  border: #dabe8e solid 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  margin-top: 20px;
}
</style>
