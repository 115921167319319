<!--
   User: Liu Yin
   Date: 2020/6/3
   Description:
 -->
<template>
  <article>
    <!-- <div class="resumepad-style">
      <div class="backdiv" @click="handleClose">
        <i class="iconfont icon-return"></i>
        返回
      </div>
    </div> -->
    <!-- <return-key @close="handleClose"></return-key> -->
    <div style="width:1212px;margin: 0 auto;padding: 20px 20px 50px 20px">
      <el-card class="box-card">
        <h3 style="text-align: center">{{ rowData.asName }}</h3>
        <div v-if="(isAnswer&&rowData.asType!==EvaluationSource.SOURCE_DISC)||rowData.asType===EvaluationSource.SOURCE_LOCAL" style="display: flex;align-items: center;justify-content: center;margin-top: 20px">
          <!-- <svg-icon icon-class="app-score" style="font-size: 18px;"></svg-icon> -->
          <p style="margin-left: 20px;font-size: 16px">
            <span v-if="rowData.reviewStatus===$dict.ReviewStatus.FINISHED">得分: {{ detailData.apScore }}分</span>
            <span v-else>评阅中...</span>
          </p>
          <p style="margin-left: 20px;font-size: 16px">总分: {{ rowData.asScore }}分</p>
        </div>
        <div v-if="rowData.reviewStatus===$dict.ReviewStatus.FINISHED&&rowData.asType===EvaluationSource.SOURCE_LOCAL">
          <exam-view v-if="asClass===1" style="margin-left: 20px;margin-right: 20px" :review-status="rowData.reviewStatus" :question-list="questionList"></exam-view>
          <ul v-else>
            <li v-for="item in synthesizeList" :key="item.asId">
              <h3 style="margin-top: 20px;color: #46a6ff">{{ item.asName }}</h3>
              <exam-view :review-status="rowData.reviewStatus" style="margin-left: 20px" :question-list="item.questions"></exam-view>
            </li>
          </ul>

          <div v-if="rowData.comment" style="margin: 20px">
            <h3>评语</h3>
            <p style="margin-top: 10px;font-size: 14px">{{ rowData.comment }}</p>
          </div>
        </div>
        <!-- <p v-if="isAnswer&&rowData.asType===EvaluationSource.SOURCE_DISC" style="margin:30px;line-height:28px" v-html="detailData.resultText"></p> -->
        <!-- <iframe v-if="isAnswer&&rowData.asType!==EvaluationSource.SOURCE_LOCAL" :src="detailData.fileUrl" style="width: 100%;min-height: 800px;margin: 30px 0;"></iframe> -->
        <el-empty v-if="rowData.reviewStatus===$dict.ReviewStatus.UN_REVIEW&&rowData.asType===EvaluationSource.SOURCE_LOCAL" description="非常抱歉给您带来不便，正在紧急人工批阅卷面中的主观题目，请稍等片刻。感谢您的理解和配合！" :image-size="300" :image="require('@/assets/images/piyue.png')"></el-empty>
        <el-empty v-if="!isAnswer&&rowData.asType!==EvaluationSource.SOURCE_LOCAL" description="请2分钟后在个人中心---我的测评查看测评报告" :image-size="300" :image="require('@/assets/images/piyue.png')"></el-empty>
      </el-card>
    </div>
  </article>
</template>

<script>

import ExamView from './components/ExamView'
import { EvaluationSource, ReviewStatus } from '../../../../utils/dict'
export default {
  components: { ExamView },
  props: {
    rowData: {
      type: Object,
      default() {
        return {}
      }
    },
    isReport: {
      type: Boolean,
      default: false
    },
    chronicle: {
      type: Object,
      default() {
        return {}
      }
    },
    // 是否是查看测评报告(true)过来
    isAnswer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ReviewStatus,
      EvaluationSource,
      detailData: {},
      questionList: [],
      historyList: [],
      asClass: '',
      synthesizeList: []
    }
  },
  mounted() {
    this.asClass = this.rowData.asClass
    // console.log(this.chronicle)
    // if (this.chronicle.apId) {
    //   this.detailData = this.chronicle
    //   this.$forceUpdate()
    // } else {
    if (this.rowData.asType === EvaluationSource.SOURCE_LOCAL) {
      this.getHistoryAnswer()
      this.getDetailData()
    }
    if (this.rowData.apId || this.chronicle.apId) {
      this.getDetailData()
    }
    // }
    this.$forceUpdate()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    getQuestionList() {
      this.$get(this.urls.testDetail, {}, [this.rowData.asId]).then(result => {
        if (result.code === 'SUCCESS') {
          if (this.asClass === 1) {
            const questions = result.data.questions
            this.questionList = this.initData(questions)
          } else {
            this.synthesizeList = result.data.children
            this.synthesizeList.forEach(async (item) => {
              const questions = await this.getSynthesizeItem(item)
              this.$set(item, 'questions', questions)
            })
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    getSynthesizeItem(item) {
      return new Promise((resolve) => {
        this.$get(this.urls.testDetail, {}, [item.asId]).then(result => {
          if (result.code === 'SUCCESS') {
            const data = result.data
            const questions = this.initData(data.questions, item)
            resolve(questions)
          }
        })
      })
    },
    getHistoryAnswer() {
      this.$get('/assess/answer/answerRecord', {}, [this.rowData.apId]).then(result => {
        if (result.code === 'SUCCESS') {
          this.historyList = result.data
          this.getQuestionList()
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    initData(questions, synthesize) {
      questions.forEach(item => {
        const match = this.historyList.find(answer => {
          if (synthesize) {
            if (answer.aqId === item.aqId && answer.evid === synthesize.asId) {
              return true
            }
          } else {
            if (answer.aqId === item.aqId) {
              return true
            }
          }
        }) || {}
        const solution = match.ansValues || ''
        item.options.forEach(option => {
          if (item.qoId.indexOf(option.qoOption) > -1) {
            option.isRightAnswer = true
          } else {
            if (solution.indexOf(option.qoOption) > -1) {
              option.isErrorAnswer = true
            }
          }
        })
        item.ansValues = match.ansValues
        // item.aqScore = match.aqScore
        item.ansScore = match.ansScore
      })
      return questions
    },
    getDetailData() {
      this.$get(this.urls.personAssessDetail, {}, [this.rowData.apId]).then(result => {
        if (result.code === 'SUCCESS') {
          this.detailData = result.data
        } else {
          this.$message.error(result.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.backdiv {
  position: fixed;
  cursor: pointer;
}
.backdiv {
  position: fixed;
  cursor: pointer;
  background: #b8f4f7;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.resumepad-style {
  position: absolute;
  top: 88px;
  right: 125px;
  z-index: 999;
}
</style>
