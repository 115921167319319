<!--
   User: Liu Yin
   Date: 2020/4/26
   Description:开始测评
 -->
<template>
  <article class="do-appraisal">
    <!-- <return-key @close="handleClose"></return-key> -->
    <div style="width:1212px;margin: 0 auto;">
      <div v-if="!showComplete">
        <!-- <div class="resumepad-style">
        <div class="backdiv" @click="handleClose">
          <i class="iconfont icon-return"></i>
          返回
        </div>
      </div> -->

        <el-steps v-if="appraisalType===2" :active="comprehensiveAssessIndex+1" align-center>
          <el-step v-for="(item,index) in appraisalData.children" :key="index" :title="item.asName"></el-step>
        </el-steps>
        <section v-if="currentQuestion" class="appraisal-area">
          <div class="area-block area-left">
            <h2 class="area-title">{{ comprehensiveChild.asName }}</h2>
            <article class="question-container">
              <p class="appraisal-hint">({{ currentQuestion.aqTopic | convert('QuestionType') }})</p>
              <h4 class="question-item-title"><span style="min-width:45px">{{ currentIndex+1 }}.</span><span>{{ currentQuestion.aqName }} &nbsp;<span v-if="currentQuestion.asType===EvaluationSource.SOURCE_LOCAL" class="grey-text brief">({{ currentQuestion.aqScore }}分)</span></span></h4>
              <!--单选题-->
              <el-radio-group v-if="currentQuestion.aqTopic===QuestionType.SINGLE_CHOICE" v-model="currentQuestion.ansValues" @keyup.stop.native="handleNextQuestion">
                <!-- <el-radio-group v-if="currentQuestion.aqTopic===QuestionType.SINGLE_CHOICE" v-model="currentQuestion.ansValues"> -->
                <div v-for="(item,index) in currentQuestion.options" :key="index" class="answer-options">
                  <el-radio :label="item.qoOption" class="answer-parent">
                    <span class="answer-content">{{ item.qoOption }}. {{ item.qoContent }}</span>
                  </el-radio>
                </div>
              </el-radio-group>
              <!--多选题-->
              <el-checkbox-group v-else-if="currentQuestion.aqTopic===QuestionType.MULTIPLE_CHOICE" v-model="currentQuestion.ansValues">
                <div v-for="(item,index) in currentQuestion.options" :key="index" class="answer-options">
                  <el-checkbox :label="item.qoOption" class="answer-parent">
                    <span>{{ item.qoOption }}. {{ item.qoContent }}</span>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <!--判断题-->
              <el-radio-group v-if="currentQuestion.aqTopic===QuestionType.JUDGE" v-model="currentQuestion.ansValues">
                <div v-for="(item,index) in currentQuestion.options" :key="index" class="answer-options">
                  <el-radio :label="item.qoOption" class="answer-parent">
                    <span class="answer-content">{{ item.qoContent }}</span>
                  </el-radio>
                </div>
              </el-radio-group>
              <!--填空题-->
              <ul v-else-if="currentQuestion.aqTopic===QuestionType.FILL_BLANK">
                <li v-for="(item,index) in currentQuestion.options" :key="index" class="answer-options">
                  <el-input v-model="item.inputValue" placeholder="请输入答案"></el-input>
                </li>
              </ul>
              <!--简答题-->
              <el-input v-else-if="currentQuestion.aqTopic===QuestionType.BRIEF_ANSWER" v-model="currentQuestion.ansValues" style="margin-top: 20px" type="textarea" placeholder="请输入答案"></el-input>
            </article>
            <div style="text-align: center;margin-top: 100px">
              <el-button v-if="isLast" type="primary" @click="commitData">提交</el-button>
              <el-button v-else type="primary" @click="handleNextQuestion">下一题</el-button>
            </div>
          </div>
          <article class="area-block area-right">
            <h2 class="navigate-title">答题卡</h2>
            <ul class="explain-container">
              <li v-for="(item,index) in explainList" :key="index" class="explain-item">
                <div class="diamond" :style="{backgroundColor:item.color}"></div>
                <p>{{ item.name }}</p>
              </li>
            </ul>
            <h2 class="navigate-title" style="margin-top: 35px">答题进度</h2>
            <ul class="progress-container">
              <li v-for="(item,index) in questionList" :key="index" class="progress-item" :style="{backgroundColor:getItemColor(item)}" @click="turnToQuestion(index)">
                <span>{{ index+1 }}</span>
              </li>
            </ul>
          </article>
        </section>
        <el-empty v-if="!currentQuestion" :image-size="200" description="暂无测评题"></el-empty>
      </div>
      <appraisal-report v-if="showComplete" :row-data="appraisalInfo" @close="handleToIndex" @startAppraisal="handleAgainAppraisal"></appraisal-report>
    </div>
  </article>
</template>

<script>
import appraisalReport from '../appraisalReport'
import { QuestionType, EvaluationSource } from '../../../../../utils/dict'

export default {
  components: {
    appraisalReport
  },
  props: {
    appraisalInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    // 历史记录
    historyAppraisal: {
      type: Object,
      default() {
        return {}
      }
    },
    // 培训计划ID
    planId: {
      type: [Object, Number],
      default: null
    }
  },
  data() {
    return {

      QuestionType,
      EvaluationSource,
      appraisalData: {},
      // 测评类型  1单项测评  2综合测评
      appraisalType: 0,
      // 当前题目
      currentQuestion: {},
      // 当前题目的位置
      currentIndex: 0,
      // 问题列表
      questionList: [],
      // 是否最后一条
      isLast: false,
      // 综合测评child位置
      comprehensiveAssessIndex: 0,
      // 综合测评当前条目
      comprehensiveChild: {},
      // 是否展示已完成
      showComplete: false,
      // 是否为重新测评
      isAgain: false,
      explainList: [
        {
          name: '已答',
          color: 'rgb(82,179,105)'
        },
        {
          name: '当前',
          color: 'rgb(255,148,42)'
        },
        {
          name: '未答',
          color: 'rgb(215,215,215)'
        }
      ],
      lastDate: Date.now()
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    handleToIndex() {
      this.$emit('toIndex')
    },
    handleAgainAppraisal() {
      this.isAgain = true
      this.currentIndex = 0
      this.comprehensiveAssessIndex = 0
      this.isLast = false
      this.getDetailData()
      this.showComplete = false
    },
    getDetailData() {
      this.toggleLoading(true)
      this.$get(this.urls.testDetail, {}, [this.appraisalInfo.asId]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          this.appraisalData = result.data
          this.initData(this.appraisalData)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    // 获取综合测评的列表
    getComprehensiveItem(id) {
      this.toggleLoading(true)
      this.$get(this.urls.testDetail, {}, [id]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.currentIndex = 0
          this.initQuestionList(data)
        } else {
          if (this.comprehensiveAssessIndex) this.comprehensiveAssessIndex--
          this.$message.error(result.msg)
        }
      }).catch(() => {
        if (this.comprehensiveAssessIndex) this.comprehensiveAssessIndex--
        this.toggleLoading(false)
      })
    },
    // 处理整体数据业务逻辑
    initData(data) {
      this.appraisalType = data.asClass
      if (this.appraisalType === 1) {
        this.initQuestionList(data)
      } else {
        const children = data.children
        this.getComprehensiveItem(children[0].asId)
      }
    },
    // 初始化问题数据
    initQuestionList(data) {
      this.comprehensiveChild = data
      if (!data.questions) {
        return
      }
      const questions = this.clone(data.questions)
      // 易普思回显qoOption>ansValues
      if (this.appraisalData.asType === EvaluationSource.SOURCE_YPS) {
        questions.forEach(question => {
          this.historyAppraisal.sheets.forEach((element, index) => {
            question.options.forEach(item => {
              if (item.qoValue === element.ansValues) {
                // eslint-disable-next-line vue/no-mutating-props
                this.historyAppraisal.sheets[index].ansValues = item.qoOption
              }
            })
          })
        })
      }
      // DISC回显qoGroup>ansValues
      if (this.appraisalData.asType === EvaluationSource.SOURCE_DISC) {
        questions.forEach(question => {
          this.historyAppraisal.sheets.forEach((element, index) => {
            question.options.forEach(item => {
              if (item.qoValue === element.ansValues) {
                // eslint-disable-next-line vue/no-mutating-props
                this.historyAppraisal.sheets[index].ansValues = item.qoGroup
              }
            })
          })
        })
      }
      // console.log(this.historyAppraisal.sheets)
      // 如果是重新测评则不需要历史记录
      const records = this.isAgain ? [] : this.historyAppraisal.sheets
      // console.log(this.historyAppraisal.sheets, this.historyAppraisal.sheets[this.historyAppraisal.sheets - 1], this.historyAppraisal.sheets[this.historyAppraisal.sheets - 1].aqId)
      if (this.historyAppraisal.sheets.length > 0) {
        questions.forEach((element, index) => {
          if (this.historyAppraisal.sheets[this.historyAppraisal.sheets.length - 1].aqId === element.aqId) {
            this.currentIndex = index
          }
        })
      } else {
        this.currentIndex = this.historyAppraisal.sheets.length
      }
      // this.currentIndex = this.historyAppraisal.sheets[this.historyAppraisal.sheets - 1].aqId

      // 遍历题目,将历史答案赋值给对应条目
      questions.forEach(question => {
        // if (this.appraisalData.asType !== EvaluationSource.SOURCE_LOCAL) {
        //   records.forEach((element, index) => {
        //     question.options.forEach(item => {
        //       if (item.qoValue === element.ansValues) {
        //         records[index].ansValues = item.qoOption
        //       }
        //     })
        //   })
        // }

        if (question.aqTopic === QuestionType.MULTIPLE_CHOICE) {
          question.ansValues = []
        }
        if (records && records.length > 0) {
          const match = records.find(recordItem => {
            if (question.aqId === recordItem.aqId) {
              return true
            }
          })
          if (match) {
            let solutionList = null
            let options = null
            switch (question.aqTopic) {
              case QuestionType.SINGLE_CHOICE:
                question.ansValues = match.ansValues
                break
              case QuestionType.MULTIPLE_CHOICE:
                question.ansValues = match.ansValues.split('')
                break
              case QuestionType.JUDGE:
                question.ansValues = match.ansValues
                break
              case QuestionType.FILL_BLANK:
                solutionList = match.ansValues.split('##')
                options = question.options
                solutionList.forEach((solution, index) => {
                  options[index].inputValue = solution
                })
                question.ansValues = match.ansValues.split('##')
                break
              case QuestionType.BRIEF_ANSWER:
                question.ansValues = match.ansValues
                break
            }

            question.ansId = match.ansId
          }
        }
      })
      this.questionList = questions
      this.updatePageInfo()
    },
    handleClose() {
      this.$emit('close')
    },
    getItemColor(item) {
      if (item.aqId === this.currentQuestion.aqId) {
        return 'rgb(255,148,42)'
      }
      const topic = item.aqTopic
      if ((topic === QuestionType.SINGLE_CHOICE || topic === QuestionType.JUDGE || topic === QuestionType.FILL_BLANK || topic === QuestionType.BRIEF_ANSWER) && item.ansValues) {
        return 'rgb(82,179,105)'
      }
      if (topic === QuestionType.MULTIPLE_CHOICE && item.ansValues.length > 0) {
        return 'rgb(82,179,105)'
      }
      return 'rgb(215,215,215)'
    },
    handleNextQuestion() {
      const current = this.questionList[this.currentIndex]
      if (this.currentUnSelect(current)) {
        this.$message.warning('请选择答案!')
        return
      }
      this.currentIndex++
      this.updatePageInfo()
      this.saveQuestion(current, 1)
    },
    // 更新题目信息
    updatePageInfo() {
      if (this.appraisalType === 2) {
        // 综合测评
        const children = this.appraisalData.children
        if (this.comprehensiveAssessIndex === children.length - 1) {
          this.isLast = this.currentIndex >= this.questionList.length - 1
        } else {
          if (this.currentIndex >= this.questionList.length) {
            if (this.isUnComplete()) {
              this.currentIndex--
              this.$message.warning('当前测评有未做题目,请完整答题')
              return
            }
            this.getComprehensiveItem(children[++this.comprehensiveAssessIndex].asId)
          }
        }
      } else {
        // 单项测评
        this.isLast = this.currentIndex >= this.questionList.length - 1
      }
      // if (this.questionList.length > 0) {
      if (this.currentIndex >= this.questionList.length - 1) {
        this.currentQuestion = this.questionList[this.questionList.length - 1]
      } else {
        this.currentQuestion = this.questionList[this.currentIndex]
      }
      // } else {
      //   this.currentQuestion = {
      //     aqAnalysis: null,
      //     aqDiffcultCount: null,
      //     aqDifficulty: null,
      //     aqId: null,
      //     aqName: null,
      //     aqOrder: null,
      //     aqRemark: null,
      //     aqScore: null,
      //     aqTopic: null,
      //     aqType: null,
      //     aqTypes: null,
      //     asId: null,
      //     asType: null,
      //     code: null,
      //     isDeleted: null,
      //     isPublish: null,
      //     msg: null,
      //     options: null,
      //     qoId: null

      //   }
      // }
    },
    /**
     * 保存答案
     * @param question 当前题目信息
     * @param type 类型  1下一步   2提交
     */
    saveQuestion(question, type) {
      // ansValues  答案/分值,local与yps取到的值不一样 apId点击开始返回的ID  evid单项测评ID  aqid 题的id  aqPid综合测评ID  ansId记录ID  recordId点击开始返回的recordId
      const params = {}
      const Values = question.options.find(item => item.qoOption === question.ansValues)
      params.ansOptions = Values ? Values.qoContent : ''
      if (this.historyAppraisal.sheets.length > 0 && this.historyAppraisal.sheets[this.currentIndex] && this.historyAppraisal.sheets[this.currentIndex].bwtTime && question.bwtTime) {
        params.bwtTime = Date.now() - this.lastDate + this.historyAppraisal.sheets[this.currentIndex].bwtTime + question.bwtTime
      } else if (this.historyAppraisal.sheets.length > 0 && this.historyAppraisal.sheets[this.currentIndex] && this.historyAppraisal.sheets[this.currentIndex].bwtTime) {
        params.bwtTime = Date.now() - this.lastDate + this.historyAppraisal.sheets[this.currentIndex].bwtTime
      } else if (question.bwtTime) {
        params.bwtTime = Date.now() - this.lastDate + question.bwtTime
      } else {
        params.bwtTime = Date.now() - this.lastDate
      }
      this.lastDate = Date.now()
      this.questionList[this.currentIndex].bwtTime = params.bwtTime
      // params.ansOptions = question.options
      //   .filter(item => item.qoOption === question.ansValues)
      //   .map(item => item.qoContent)
      //   .join('')
      params.recordId = this.historyAppraisal.recordId
      params.apId = this.historyAppraisal.apId

      params.aqId = question.aqId
      params.ansId = question.ansId
      let list = null
      let options = null

      switch (question.aqTopic) {
        case QuestionType.SINGLE_CHOICE:
          params.ansValues = question.ansValues
          break
        case QuestionType.MULTIPLE_CHOICE:
          list = question.ansValues.sort(this.sortList)
          params.ansValues = list.join('')
          break
        case QuestionType.JUDGE:
          params.ansValues = question.ansValues
          break
        case QuestionType.FILL_BLANK:
          options = question.options
          if (options && options.length > 0) {
            const list = options.map(item => {
              return item.inputValue
            })
            params.ansValues = list.join('##')
          }
          break
        case QuestionType.BRIEF_ANSWER:
          params.ansValues = question.ansValues
          break
      }

      // ansValues   上面取值为local的,下面取值为yps的,上面取得值是ansValues,也就是ABCD选项,下面取得是qoValue,也就是分值
      // 易普思存入ansValues>qoValue
      if (this.appraisalData.asType === EvaluationSource.SOURCE_YPS) {
        params.ansValues = Values ? Values.qoValue : ''
      }
      // DISC存入ansValues>qoGroup
      if (this.appraisalData.asType === EvaluationSource.SOURCE_DISC) {
        params.ansValues = Values ? Values.qoGroup : ''
      }
      if (this.appraisalType === 1) {
        // 单项测评
        params.evid = this.appraisalData.asId
      } else {
        // 综合测评
        params.aqPid = this.appraisalData.asId
        params.evid = this.comprehensiveChild.asId
      }

      if (this.planId) {
        params.planId = this.planId
      }

      this.$post(this.urls.saveItemQuestion, params).then(result => {
        if (result.code === 'SUCCESS') {
          question.ansId = result.data
          if (type === 2) {
            // 提交
            this.finishAppraisal()
          }
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
      })
    },
    sortList(a, b) {
      return a.charCodeAt(0) - b.charCodeAt(0)
    },
    // 结束测评
    finishAppraisal() {
      const params = {}
      params.recordId = this.historyAppraisal.recordId
      params.apId = this.historyAppraisal.apId
      params.evid = this.appraisalData.asId
      if (this.planId) {
        params.planId = this.planId
      }
      this.toggleLoading(true)
      this.$post(this.urls.finishAppraisal, params).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          // 提交
          this.$message.success('测评已提交')
          this.$emit('commitSuccess')
          // eslint-disable-next-line vue/no-mutating-props
          this.appraisalInfo.apId = result.data.apId
          // eslint-disable-next-line vue/no-mutating-props
          this.appraisalInfo.asType = result.data.asType
          // eslint-disable-next-line vue/no-mutating-props
          this.appraisalInfo.reviewStatus = result.data.reviewStatus
          this.showComplete = true
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    turnToQuestion(index) {
      // console.log(index, this.currentIndex, this.questionList, this.questionList[this.currentIndex], 'question')
      const current = this.questionList[this.currentIndex]

      this.currentIndex = index
      this.updatePageInfo()
      if (!this.currentUnSelect(current)) {
        this.saveQuestion(current, 1)
      }
    },
    commitData() {
      const current = this.questionList[this.currentIndex]
      if (this.currentUnSelect(current)) {
        this.$message.warning('请选择答案!')
        return
      }
      if (this.isUnComplete()) {
        this.$message.warning('当前测评有未做题目,请完整答题')
        return
      }
      this.saveQuestion(current, 2)
    },
    // 校验是否选择了答案
    currentUnSelect(current) {
      switch (current.aqTopic) {
        case QuestionType.SINGLE_CHOICE:
        case QuestionType.JUDGE:
          return !current.ansValues
        case QuestionType.MULTIPLE_CHOICE:
          return !current || current.ansValues.length === 0
      }
    },
    // 检查是否完整
    isUnComplete() {
      return this.questionList.some(item => {
        if (this.currentUnSelect(item)) {
          return true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.do-appraisal {
  min-height: 600px;
}
.appraisal-area {
  margin-top: 30px;
  display: flex;
  .area-block {
    border: gray dashed 1px;
    border-radius: 10px;
    padding: 30px;
  }
  .area-left {
    width: 70%;
    flex-shrink: 0;
    .area-title {
      font-weight: bold;
      font-size: 25px;
      color: rgb(3, 106, 219);
    }
    .question-container {
      margin-top: 40px;
      margin-left: 30px;
      .appraisal-hint {
        color: rgb(123, 123, 123);
        font-size: 18px;
      }
      .question-item-title {
        line-height: 35px;
        margin-top: 25px;
        display: flex;
        font-size: 22px;
        white-space: pre-wrap;
        word-break: break-all;
        span {
          font-size: 22px;
        }
      }
      .answer-options {
        margin-top: 35px;
        margin-left: 20px;
        .answer-parent {
          width: 100%;
          white-space: pre-wrap;
          word-break: break-all;
          display: flex;
        }
        .answer-content {
          font-size: 18px;
        }
      }
    }
  }
  .area-right {
    flex-grow: 1;
    margin-left: 40px;
    .navigate-title {
      font-size: 22px;
    }
    .explain-container {
      display: flex;
      margin-top: 25px;
      .explain-item {
        margin-right: 30px;
        .diamond {
          width: 35px;
          height: 35px;
        }
        p {
          margin-top: 10px;
        }
      }
    }
    .progress-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .progress-item {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
  }
}
.backdiv {
  position: fixed;
  cursor: pointer;
  background: #b8f4f7;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.resumepad-style {
  position: absolute;
  top: 88px;
  right: 125px;
  z-index: 999;
}
</style>
