<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:简历列表组件
 -->
<template>
  <article id="myArticle" class="appraise">
    <el-row :gutter="20" style="max-width: 1280px;margin: 0 auto;">
      <el-col
        v-for="(item, index) in records"
        :key="index"
        :xs="12"
        :sm="8"
        :md="6"
        :lg="6"
        :xl="6"
        class="item-container"
      >
        <div style="position: relative">
          <div
            v-if="item.collation !== 0"
            class="keynote"
            :style="{
              backgroundImage: 'url(' + imageUrl(item.collation) + ')',
            }"
          >
            <div class="collationfont">{{ convert(item.collation) }}</div>
          </div>
          <el-card :body-style="{ padding: '0px' }">
            <article class="item-parent" @click="showIntroduce(item)">
              <div class="item-top">
                <img :src="getImage(item)" @error="handleError">
                <div class="masking">
                  <div v-if="item.completeNum" class="clickNum"><i class="el-icon-finished"></i><span class="marright">{{ item.completeNum }}</span></div>
                  <div v-if="item.clickCount" class=" coureHours"><i class="el-icon-view"></i><span class="marright">{{ item.clickCount }}</span></div>
                </div>
              </div>
              <div class="item-bottom">
                <div class="item-name" :title="item.asName">
                  {{ item.asName }}
                </div>
                <div v-if="!item.platformPrice" class="freebase">免费</div>
                <div v-else class="paycolor">￥{{ item.platformPrice }}</div>
              </div>
            </article>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <ul class="appraisal-container" style="padding: initial">
      <li v-for="(item,index) in records" :key="index" class="item-container" :class="(index + 1) % cardNum == 0 ? 'clearMargin' : ''" @click="showIntroduce(item)">
        <el-card :body-style="{ padding: '0px' }" class="card" style="position: relative;">
          <div class="item-content" style="width: 100%;">
            <div style="overflow: hidden;border-radius: 4px 4px 0 0;"><img class="item-image" :src="getImage(item)" @error="handleError"></div>

            <div class="hoverDetail">
              <div class="detailsIntro">
                <div v-html="item.asDetails"></div>
              </div>
            </div>
            <div v-if="item.planname" class="posiPlanname">{{ item.planname }}</div>
            <div class="item-bottom">
              <span class="hidden-font" :title="item.asName">{{ item.asName }}</span>
            </div>
          </div>
          <div v-if="!isMine" class="price">￥{{ item.platformPrice }}</div>
        </el-card>
      </li>
    </ul> -->
    <section style="text-align: center">
      <el-pagination
        class="pageAction"
        :current-page="currentPage"
        :page-size="12"
        :background="false"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>
  </article>
</template>

<script>
import imageTop from '../../../../../assets/images/appraise-top.png'
import { EvaluationSource } from '../../../../../utils/dict'
export default {
  props: {
    records: {
      type: Array,
      default() {
        return []
      }
    },
    isMine: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      EvaluationSource,
      historyList: [
        {
          createTime: '2023-06-27'
        }
      ],
      detailData: {},
      cardNum: 4,
      cardmaright: null
    }
  },
  mounted() {
    // const myArticle = document.getElementById('myArticle')
    // var pageWidth = Math.round(window.innerWidth)
    // pageWidth = pageWidth * 0.77
    // if (pageWidth < 1212) {
    //   this.cardNum = 3
    // } else if (pageWidth >= 1212 && pageWidth < 1515) {
    //   this.cardNum = 4
    // } else if (pageWidth >= 1515) {
    //   this.cardNum = 5
    // }
    // this.cardmaright = (pageWidth - (303 * this.cardNum) - 13.1) / (this.cardNum - 1)
  },
  methods: {
    convert(data) {
      switch (data) {
        case 1:
          return '专业'
        case 2:
          return '推荐'
        case 3:
          return '重点'
      }
    },
    imageUrl(data) {
      switch (data) {
        case 1:
          return require('../../../../../assets/images/redC.png')
          // return require('../../../../../assets/images/keypoint.png')
        case 2:
          return require('../../../../../assets/images/redB.png')
          // return require('../../../../../assets/images/characteristic.png')
        case 3:
          return require('../../../../../assets/images/redA.png')
          // return require('../../../../../assets/images/major.png')
      }
    },
    seeHisReport(item, val) {
      this.$emit('showReport', item, val)
    },
    showHistoryReport(item) {
      let params = {}
      item.plid !== -1 ? (params.plid = item.plid) : (params = null)
      this.$get(this.urls.fetchLastFiveRecords + '/' + item.asId, params)
        .then((result) => {
          if (result.code === 'SUCCESS') {
            this.historyList = result.data
          }
        })
        .catch((e) => {})
    },
    handleCurrentChange(num) {
      console.log(num)
      this.$emit('changePage', num)
    },
    getImage(item) {
      if (item.asUrl) return item.asUrl
      return imageTop
    },
    // 图片链接失效/错误给默认图
    handleError(event) {
      const img = event.srcElement
      img.src = imageTop
      img.onerror = null // 防止闪图
    },
    // 开始测评
    startAppraisal(item) {
      this.$emit('startAppraisal', item)
    },
    showIntroduce(item) {
      if (this.isMine) {
        this.$emit('showIntroduce', item)
      } else {
        this.startAppraisal(item)
      }
    },
    getDetali(item) {
      this.$get(this.urls.personAssessDetail, {}, [item.apId]).then(
        (result) => {
          if (result.code === 'SUCCESS') {
            this.detailData = result.data
            this.$emit('showReport', item, this.detailData)
          } else {
            this.$message.error(result.msg)
          }
        }
      )
    },
    showReport(item) {
      this.getDetali(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.appraise {
  // background-color: white;
  padding-bottom: 20px;

}
.appraise-list {
  display: flex;
  flex-wrap: wrap;
}
.item-container:hover {
  transition: all 0.5s ease;
  transform: translateY(-5%);
  .item-parent {
    .item-top {
      .masking::before {
        display: none;
      }
      overflow: hidden;
      .coureHours {
        display: none;
      }
      .clickNum {
        display: none;
      }
      img {
        transition: all 0.5s ease;
        transform: scale(1.1);
      }
    }
  }
}

.item-container {
  // width: 25%;
  // box-sizing: border-box;
  padding: 8px;
  min-height: 235px;

  .item-parent {
    cursor: pointer;
    transition: all 0.6s;
    min-height: 235px;
    .masking::before {
      content: "";
      // background-color: rgba(0,0,0,.5);
      background: linear-gradient(
        0deg,
        #000000d9,
        rgb(0 0 0 / 69%),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.17),
        hsla(0, 0%, 100%, 0)
      );
      width: 100%;
      height: 35px;
      position: absolute;
      bottom: 2px;
      left: 0;
    }
    .item-top {
      position: relative;
      height: 71.8%;
      width: 100%;

      img {
        background-image: url("../../../../../assets/images/appraise-top.png");
        background-size: 100% 100%;
        background-position: 50% 50%;
        width: 100%;
        height: 170px;
        overflow: hidden;
      }
    }
    .item-bottom {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5.5% 0;
      .label-parent {
        margin-top: 20px;
      }
      .item-name {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
            line-height: 16px;
        width: 230px;
      }
      .item-speaker {
      }
      .item-content {
        margin-top: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: rgb(135, 135, 135);
      }
      .label {
        background-color: rgb(231, 146, 71);
        padding: 3px 10px;
        color: white;
        font-size: 17px;
      }
    }
  }
}

.pageAction {
  margin-top: 30px;
}
::v-deep .el-pagination {
  button {
    background: initial;
    .el-icon {
      font-size: 17px;
    }
  }
  .number {
    background: initial;
    font-size: 16px;
  }
}
::v-deep .el-card {
  border-radius: 8px;
  // min-height: 235px;
  position: relative;
}
.coureHours {
  position: absolute;
  bottom: 6px;
  left: 10px;

  color: #fff;
}
.clickNum {
  position: absolute;
  bottom: 6px;
  right: 10px;

  color: #fff;
}
.keynote {
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 9;
  color: #fff;
  width: 62px;
  height: 62px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.marright {
  margin-left: 5px;
  font-size: 12px;
}
.freebase {
  color: #fff;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  height: 18px;
  line-height: 17px;
  border-radius: 2px;
  text-align: center;
  background-color: #fa9841;
  padding: 0 4.5px;
  min-width: 33px;
  box-sizing: border-box;
}
.paycolor {
  font-size: 16px;
  padding: 0px;
  margin-left: -4px;
  font-weight: 600;
  color: #c7000b;
  line-height: 18px;
  float: right;
  background-color: rgba(223, 225, 230, 0);
}
.collationfont {
  margin-top: -7px;
}
.keynote {
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 9;
  color: #fff;
  width: 62px;
  height: 62px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
</style>
